export const useRandomString = (
    length: number = 10,
    charSet?: string,
): string => {
    const defaultCharSet: string =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const usedCharSet: string = charSet || defaultCharSet;

    let result = '';
    const charSetLength = usedCharSet.length;

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charSetLength);
        result += usedCharSet[randomIndex];
    }

    return result;
};
