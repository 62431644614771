import type {
    JamBaseNotificationLink,
    JamBaseNotificationVariant,
} from '../components/JamBaseNotification.vue';

export type Notification = {
    closeButton?: boolean;
    duration: NotificationVisibilityDuration;
    id: string;
    link?: JamBaseNotificationLink;
    message: string;
    variant?: JamBaseNotificationVariant;
    onClose: () => boolean;
    icon?: string;
};

export type NotificationOptions = {
    closeButton?: boolean;
    link?: JamBaseNotificationLink;
    icon?: string;
};

export type NotificationVisibilityDuration = 'short' | 'long';

export const useNotification = () => {
    const notifications = useState('notifications', (): Notification[] => []);
    const removeSchedule = ref<{ [key: string]: NodeJS.Timeout }>({});
    const getCount: number = 2;
    const durationShortMs: number = 2200;
    const durationLongMs: number = 5000;

    const remove = (id: string) => {
        notifications.value = notifications.value.filter(
            (notification) => notification.id !== id,
        );
        if (removeSchedule.value[id]) {
            clearTimeout(removeSchedule.value[id]);
            delete removeSchedule.value[id];
        }
    };

    const scheduleToRemove = (id: string, duration: number) => {
        if (typeof removeSchedule.value[id] !== 'undefined') {
            return;
        }
        removeSchedule.value[id] = setTimeout(() => {
            remove(id);
        }, duration);
    };

    const set = (
        variant: JamBaseNotificationVariant,
        message: string,
        options: NotificationOptions,
        duration: NotificationVisibilityDuration,
    ) => {
        const id = useRandomString();
        const newNotification: Notification = {
            ...options,
            ...{
                duration: duration,
                id: id,
                message: message,
                onClose: () => {
                    remove(id);
                    return false;
                },
                variant: variant,
            },
        };
        notifications.value.push(newNotification);
    };

    const error = (
        message: string,
        options: NotificationOptions = {},
        duration: NotificationVisibilityDuration = 'short',
    ) => {
        set('error', message, options, duration);
    };

    const info = (
        message: string,
        options: NotificationOptions = {},
        duration: NotificationVisibilityDuration = 'short',
    ) => {
        set('info', message, options, duration);
    };

    const queue = computed<Notification[]>(() => {
        const result: Notification[] = notifications.value.slice(0, getCount);
        result.forEach((notification) => {
            scheduleToRemove(
                notification.id,
                notification.duration === 'short'
                    ? durationShortMs
                    : durationLongMs,
            );
        });

        return result;
    });

    return {
        error,
        info,
        notifications,
        queue,
    };
};
